@import "default/color_variable";
@import "default/typography";
@import "common/general";
@import "common/slider";
@import "common/video-modal";
@import "common/sidebar";

.cs-hero.cs-style7.cs-fixed_bg-nut{
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}